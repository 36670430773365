import {
  NativeDataSettingsConfigResponse,
  DataSettingFieldType,
  DataSettingsKeyType,
  Unit21DataClassifier,
  CustomDataSettingsConfigResponse,
  FormattedDataItem,
  FormattedDataCensorshipStatus,
  CensorshipSetting,
} from 'app/modules/dataSettings/responses';

export const INITIAL_FORMATTED_DATA_ITEM: FormattedDataItem = {
  formatted_value: undefined,
  is_parseable: false,
  raw_value: undefined,
  censorship: FormattedDataCensorshipStatus.UNCENSORED,
};

export const MOCK_NATIVE_DATA_SETTINGS: NativeDataSettingsConfigResponse[] = [
  {
    configured_flow: null,
    data_type: DataSettingFieldType.NUMBER,
    description: 'a description',
    enum_set: [],
    exported: true,
    id: 68535,
    key_type: DataSettingsKeyType.NATIVE,
    last_customer_update_time: 'Tue, 26 Mar 2024 18:04:00 GMT',
    matchable: false,
    native_field: 'Sent amount',
    native_key: 'sent_amount',
    recent_values: ['234666689223.976'],
    formatted_recent_values: [
      {
        ...INITIAL_FORMATTED_DATA_ITEM,
        raw_value: '234666689223.976',
        formatted_value: 234666689223.976,
        is_parseable: true,
      },
    ],
    rendering_options: undefined,
    rules: [],
    unit21_data_classifier: Unit21DataClassifier.EVENT,
    user_facing_label: 'Sent amount',
    censorship_setting: CensorshipSetting.ALWAYS_VISIBLE,
    agent_ids_with_censorship_read_permission: [],
    team_ids_with_censorship_read_permission: [],
  },
  {
    configured_flow: null,
    data_type: DataSettingFieldType.NUMBER,
    description: 'a description',
    enum_set: [],
    exported: false,
    id: 68530,
    key_type: DataSettingsKeyType.NATIVE,
    last_customer_update_time: 'Tue, 26 Mar 2024 18:04:00 GMT',
    matchable: false,
    native_field: 'External fee',
    native_key: 'external_fee',
    recent_values: ['3678333558.2083'],
    formatted_recent_values: [
      {
        ...INITIAL_FORMATTED_DATA_ITEM,
        raw_value: '3678333558.2083',
        formatted_value: 3678333558.2083,
        is_parseable: true,
      },
    ],
    rendering_options: undefined,
    rules: [],
    unit21_data_classifier: Unit21DataClassifier.EVENT,
    user_facing_label: 'External fee',
    censorship_setting: CensorshipSetting.ALWAYS_VISIBLE,
    agent_ids_with_censorship_read_permission: [],
    team_ids_with_censorship_read_permission: [],
  },
  {
    configured_flow: null,
    data_type: DataSettingFieldType.ENTITY_REFERENCE,
    description: 'a description',
    enum_set: [],
    exported: true,
    id: 233041,
    key_type: DataSettingsKeyType.NATIVE,
    last_customer_update_time: 'Wed, 27 Mar 2024 03:33:10 GMT',
    matchable: false,
    native_field: 'Entity',
    native_key: 'entity_id',
    recent_values: ['"rakuYBkRJF"', '"yucJmPLBWp"'],
    formatted_recent_values: [
      {
        ...INITIAL_FORMATTED_DATA_ITEM,
        raw_value: 'rakuYBkRJF',
        formatted_value: ['rakuYBkRJF'],
        is_parseable: false,
      },
      {
        ...INITIAL_FORMATTED_DATA_ITEM,
        raw_value: 'yucJmPLBWp',
        formatted_value: ['yucJmPLBWp'],
        is_parseable: false,
      },
    ],
    rendering_options: undefined,
    rules: [],
    unit21_data_classifier: Unit21DataClassifier.ACTION_EVENT,
    user_facing_label: 'Entity',
    censorship_setting: CensorshipSetting.ALWAYS_VISIBLE,
    agent_ids_with_censorship_read_permission: [],
    team_ids_with_censorship_read_permission: [],
  },
  {
    configured_flow: null,
    data_type: DataSettingFieldType.ENTITY_REFERENCE,
    description: 'a description',
    enum_set: [],
    exported: false,
    id: 233037,
    key_type: DataSettingsKeyType.NATIVE,
    last_customer_update_time: 'Tue, 26 Mar 2024 18:04:00 GMT',
    matchable: false,
    native_field: 'Sender',
    native_key: 'sender_entity_id',
    recent_values: ['"yUKquCbMek"', '"vnAyhbugNp"'],
    formatted_recent_values: [
      {
        ...INITIAL_FORMATTED_DATA_ITEM,
        raw_value: 'yUKquCbMek',
        formatted_value: ['yUKquCbMek'],
        is_parseable: false,
      },
      {
        ...INITIAL_FORMATTED_DATA_ITEM,
        raw_value: 'vnAyhbugNp',
        formatted_value: ['vnAyhbugNp'],
        is_parseable: false,
      },
    ],
    rendering_options: undefined,
    rules: [],
    unit21_data_classifier: Unit21DataClassifier.EVENT,
    user_facing_label: 'Sender',
    censorship_setting: CensorshipSetting.ALWAYS_VISIBLE,
    agent_ids_with_censorship_read_permission: [],
    team_ids_with_censorship_read_permission: [],
  },
  {
    configured_flow: null,
    data_type: DataSettingFieldType.INSTRUMENT_REFERENCE,
    description: 'a description',
    enum_set: [],
    exported: false,
    id: 233038,
    key_type: DataSettingsKeyType.NATIVE,
    last_customer_update_time: 'Tue, 26 Mar 2024 18:04:00 GMT',
    matchable: false,
    native_field: 'Sender instrument',
    native_key: 'sender_instrument_id',
    recent_values: ['"rSMJBbpJtd"', '"iGCMIuvRZH"'],
    formatted_recent_values: [
      {
        ...INITIAL_FORMATTED_DATA_ITEM,
        raw_value: 'rSMJBbpJtd',
        formatted_value: ['rSMJBbpJtd'],
        is_parseable: false,
      },
      {
        ...INITIAL_FORMATTED_DATA_ITEM,
        raw_value: 'iGCMIuvRZH',
        formatted_value: ['iGCMIuvRZH'],
        is_parseable: false,
      },
    ],
    rendering_options: undefined,
    rules: [],
    unit21_data_classifier: Unit21DataClassifier.EVENT,
    user_facing_label: 'Sender instrument',
    censorship_setting: CensorshipSetting.ALWAYS_VISIBLE,
    agent_ids_with_censorship_read_permission: [],
    team_ids_with_censorship_read_permission: [],
  },
  {
    configured_flow: null,
    data_type: DataSettingFieldType.ENUM,
    description: 'a description',
    enum_set: ['cad', 'eur'],
    exported: true,
    id: 68536,
    key_type: DataSettingsKeyType.NATIVE,
    last_customer_update_time: 'Tue, 26 Mar 2024 18:04:00 GMT',
    matchable: false,
    native_field: 'Sent currency',
    native_key: 'sent_currency',
    recent_values: ['"usd"'],
    formatted_recent_values: [
      {
        ...INITIAL_FORMATTED_DATA_ITEM,
        raw_value: 'usd',
        formatted_value: ['usd'],
        is_parseable: false,
      },
    ],
    rendering_options: undefined,
    rules: [],
    unit21_data_classifier: Unit21DataClassifier.EVENT,
    user_facing_label: 'Sent currency',
    censorship_setting: CensorshipSetting.ALWAYS_VISIBLE,
    agent_ids_with_censorship_read_permission: [],
    team_ids_with_censorship_read_permission: [],
  },
];

export const MOCK_CUSTOM_DATA_SETTINGS: CustomDataSettingsConfigResponse[] = [
  {
    configured_flow: null,
    data_type: DataSettingFieldType.ENUM,
    description: 'a description',
    enum_set: ['begin'],
    exported: false,
    id: 234631,
    key_path: ['method'],
    key_type: DataSettingsKeyType.CUSTOM,
    last_customer_update_time: 'Tue, 02 Jan 2024 16:30:16 GMT',
    matchable: false,
    recent_values: ['"begin"'],
    formatted_recent_values: [
      {
        ...INITIAL_FORMATTED_DATA_ITEM,
        raw_value: 'begin',
        formatted_value: ['begin'],
        is_parseable: false,
      },
    ],
    rendering_options: undefined,
    rules: [],
    semantic_type: undefined,
    unit21_data_classifier: Unit21DataClassifier.ENTITY,
    user_facing_label: 'A Method',
    censorship_setting: CensorshipSetting.ALWAYS_VISIBLE,
    agent_ids_with_censorship_read_permission: [],
    team_ids_with_censorship_read_permission: [],
  },
  {
    configured_flow: null,
    data_type: DataSettingFieldType.DATE,
    description: 'a description',
    enum_set: [],
    exported: false,
    id: 101373,
    key_path: ['provide'],
    key_type: DataSettingsKeyType.CUSTOM,
    last_customer_update_time: null,
    matchable: false,
    recent_values: [],
    formatted_recent_values: [],
    rendering_options: undefined,
    rules: [],
    semantic_type: undefined,
    unit21_data_classifier: Unit21DataClassifier.ACTION_EVENT,
    user_facing_label: 'Provider',
    censorship_setting: CensorshipSetting.ALWAYS_VISIBLE,
    agent_ids_with_censorship_read_permission: [],
    team_ids_with_censorship_read_permission: [],
  },
  {
    configured_flow: null,
    data_type: DataSettingFieldType.TEXT,
    description: 'a description',
    enum_set: [],
    exported: false,
    id: 236481,
    key_path: ['file_name'],
    key_type: DataSettingsKeyType.CUSTOM,
    last_customer_update_time: null,
    matchable: false,
    recent_values: [],
    formatted_recent_values: [],
    rendering_options: undefined,
    rules: [],
    semantic_type: undefined,
    unit21_data_classifier: Unit21DataClassifier.EVENT,
    user_facing_label: 'File Name',
    censorship_setting: CensorshipSetting.ALWAYS_VISIBLE,
    agent_ids_with_censorship_read_permission: [],
    team_ids_with_censorship_read_permission: [],
  },
  {
    configured_flow: null,
    data_type: DataSettingFieldType.NUMBER,
    description: 'a description',
    enum_set: [],
    exported: false,
    id: 58976,
    key_path: ['account_number'],
    key_type: DataSettingsKeyType.CUSTOM,
    last_customer_update_time: null,
    matchable: false,
    recent_values: [],
    formatted_recent_values: [],
    rendering_options: undefined,
    rules: [],
    semantic_type: undefined,
    unit21_data_classifier: Unit21DataClassifier.INSTRUMENT,
    user_facing_label: 'Account Number',
    censorship_setting: CensorshipSetting.ALWAYS_VISIBLE,
    agent_ids_with_censorship_read_permission: [],
    team_ids_with_censorship_read_permission: [],
  },
];
