import {
  AdminAlertContentPayload,
  AdminAlertHitPayload,
  AdminOrgSettingsPayload,
  AdminRuleContentPayload,
  AdminRuleExecutionPayload,
  AdminRuleQueryDiffCountsPayload,
  AdminRuleQueryPayload,
  AdminRuleSchedulePayload,
  AdminRuleValidationPayload,
  AdminScenarioConfigsPayload,
  UpdateOrgsScenarioConfigPayload,
  UpdateRuleScheduleRetryCountPayload,
  UpdateRuleScheduleStatusPayload,
  UpdateRuleTimeoutPayload,
  UpdateRuleValidationStatusPayload,
} from 'app/modules/rulesAdmin/types/requests';
import {
  AdminAlertContentResponse,
  AdminAlertHitResponse,
  AdminOrgSettingsResponse,
  AdminRuleContentResponse,
  AdminRuleExecutionResponse,
  AdminRuleQueryDiffCountsResponse,
  AdminRuleQueryResponse,
  AdminRuleScheduleResponse,
  AdminRuleValidationResponse,
  AdminScenarioConfigsResponse,
  RuleAdminFilterInfoResponse,
} from 'app/modules/rulesAdmin/types/responses';
import { get, patch, post } from 'app/shared/utils/fetchr';

export const ruleAdminFilterInfo = (): Promise<RuleAdminFilterInfoResponse> =>
  get('/rules/admin/filter-info');

export const adminScenarioConfigs = (
  payload: AdminScenarioConfigsPayload,
): Promise<AdminScenarioConfigsResponse> =>
  post('/rules/admin/scenario-configs', payload);

export const adminRuleContent = (
  payload: AdminRuleContentPayload,
): Promise<AdminRuleContentResponse> =>
  post('/rules/admin/rule-content', payload);

export const adminRuleQuery = (
  payload: AdminRuleQueryPayload,
): Promise<AdminRuleQueryResponse> => post('/rules/admin/rule-query', payload);

export const adminRuleQueryDiffCounts = (
  payload: AdminRuleQueryDiffCountsPayload,
): Promise<AdminRuleQueryDiffCountsResponse> =>
  post('/rules/admin/rule-query-diff/rule-query-counts', payload);

export const adminRuleExecution = (
  payload: AdminRuleExecutionPayload,
): Promise<AdminRuleExecutionResponse> =>
  post('/rules/admin/rule-execution', payload);

export const adminRuleValidation = (
  payload: AdminRuleValidationPayload,
): Promise<AdminRuleValidationResponse> =>
  post('/rules/admin/rule-validation', payload);

export const adminRuleSchedule = (
  payload: AdminRuleSchedulePayload,
): Promise<AdminRuleScheduleResponse> =>
  post('/rules/admin/rule-schedule', payload);

export const updateRuleScheduleStatus = (
  payload: UpdateRuleScheduleStatusPayload,
): Promise<void> =>
  patch('/rules/admin/update-rule-schedule-schedule-status', payload);

export const updateRuleScheduleRetryCount = (
  payload: UpdateRuleScheduleRetryCountPayload,
): Promise<void> =>
  patch('/rules/admin/update-rule-schedule-retry-count', payload);

export const updateRuleTimeout = (
  payload: UpdateRuleTimeoutPayload,
): Promise<void> => patch('/rules/admin/update-rule-timeout', payload);

export const updateOrgsScenarioConfigs = (
  payload: UpdateOrgsScenarioConfigPayload,
): Promise<void> => patch('/rules/admin/update-scenario-configs', payload);

export const updateRuleValidationStatus = (
  payload: UpdateRuleValidationStatusPayload,
): Promise<void> =>
  patch('/rules/admin/update-rule-validation-status', payload);

export const adminAlertContent = (
  payload: AdminAlertContentPayload,
): Promise<AdminAlertContentResponse> =>
  post('/rules/admin/alert-content', payload);

export const adminAlertHit = (
  payload: AdminAlertHitPayload,
): Promise<AdminAlertHitResponse> => post('/rules/admin/alert-hit', payload);

export const adminOrgSettings = (
  payload: AdminOrgSettingsPayload,
): Promise<AdminOrgSettingsResponse[]> =>
  post('/rules/admin/org-settings', payload);
