import {
  DataSettingFieldType,
  OrgDataSettingsConfig,
  OrgDataSettingsConfigResponse,
  Unit21DataClassifier,
  SemanticTypes,
  NativeDataSettingsConfigResponse,
  CensorshipSetting,
} from 'app/modules/dataSettings/responses';
import { AsyncStatus } from 'app/shared/types/utils/asyncStatus';
import {
  U21ChipColor,
  U21ChipVariant,
} from 'app/shared/u21-ui/components/display/U21Chip';
import { RuleStatus } from 'app/modules/rules/models';
import { Filter } from 'app/modules/filters/models';

export interface EmptyOverlayState {
  overlay: 'NONE' | 'ADD_CUSTOM_DATA';
}

export interface DataOverlayState {
  overlay:
    | 'EDIT_DATA_SETTING'
    | 'DELETE_CUSTOM_DATA'
    | 'SHOW_MORE_DATA_SETTING';
  id: number;
}
export type OverlayState = EmptyOverlayState | DataOverlayState;

export interface DataSettingsState {
  dataSettings: AsyncStatus<OrgDataSettingsConfigResponse>;
  overlay: OverlayState;
  filters: Filter[];
  dataSettingsById: Record<string, OrgDataSettingsConfig | undefined>;
  dataSettingsByNativeKey: Record<
    Unit21DataClassifier,
    Record<string, NativeDataSettingsConfigResponse | undefined>
  >;
}

interface RelatedRulesChipColors {
  color: U21ChipColor;
  style: U21ChipVariant;
}

export type RelatedRulesChipPalette = Record<
  RuleStatus,
  RelatedRulesChipColors
>;

export interface AddOrEditDataSettingsForm {
  enum_set: string[];
  configured_flow?: ConfiguredFlow;
  data_type: DataSettingFieldType;
  description?: string | null;
  exported: boolean;
  key_path: string;
  matchable?: boolean;
  semantic_type?: SemanticTypes | null;
  unit21_data_classifier: Unit21DataClassifier;
  user_facing_label?: string | null;
  rendering_options?: OrgDataSettingsConfig['rendering_options'];
  censorship_setting: CensorshipSetting;
  team_ids_with_censorship_read_permission: number[];
  agent_ids_with_censorship_read_permission: number[];
}

export interface LabelWithDescription {
  label: string;
  description: string;
}

export enum DataSettingsColumnId {
  DEFAULT_NAME = 'DEFAULT_NAME',
  FRIENDLY_NAME = 'FRIENDLY_NAME',
  FIELD_TYPE = 'FIELD_TYPE',
  DATA_TYPE = 'DATA_TYPE',
  OBJECT_TYPE = 'OBJECT_TYPE',
  EXPORTED = 'EXPORTED',
  UPDATED_AT = 'UPDATED_AT',
  USE_IN_GRAPH_BASED_RULES = 'USE_IN_GRAPH_BASED_RULES',
  SEMANTIC_TYPE = 'SEMANTIC_TYPE',
  DESCRIPTION = 'DESCRIPTION',
  RELATED_RULES = 'RELATED_RULES',
  RECENT_VALUES = 'RECENT_VALUES',
  ENUM_LIST = 'ENUM_LIST',
}

export type ConfiguredFlow = 'INBOUND' | 'OUTBOUND' | 'OTHER';
