import { SyntheticEvent } from 'react';
import { TableConfigType } from 'app/shared/CustomConfig/models';
import { TableConfig } from 'app/shared/pagination/models';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { RuleAdminResponseType } from 'app/modules/rulesAdmin/models';
import { FincenCtrTablePages } from 'app/modules/fincenCtr/models';
import { FrontendColumnDefinition } from 'app/modules/tableSettings/types';

export interface SidebarTableConfigItemData {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  portal: HTMLDivElement;
  column: TableConfig | FrontendColumnDefinition;
  toggleColumns: (checked: boolean, e: SyntheticEvent) => void;
}

export enum SidebarComponentTypes {
  ENTITY = '@@sidebar/ENTITY',
  INSTRUMENT = '@@sidebar/INSTRUMENT',
  TRANSACTION = '@@sidebar/TRANSACTION',
  ACTION_EVENT = '@@sidebar/ACTION_EVENT',
  ALERT = '@@sidebar/ALERT',
  TABLE_CONFIG = '@@sidebar/TABLE_CONFIG',
  CASE = '@@sidebar/CASE',
  SAR = '@@sidebar/SAR',
  FILINGS_BATCH = '@@sidebar/FINCEN_CTR',
  LINK_ANALYSIS_OBJECT = '@@sidebar/LINK_ANALYSIS_OBJECT',
  PERMISSIONS = '@@sidebar/PERMISSIONS', // devtool
  FEATURE_FLAGS = '@@sidebar/FEATURE_FLAGS', // devtool
  RULE_ADMIN = '@@sidebar/RULE_ADMIN',
  FFIP_VIEW_ERRORS = '@@sidebar/FFIP_VIEW_ERRORS',
  CREATE_EDIT_DATA_SETTING = '@@sidebar/CREATE_EDIT_DATA_SETTING',
  CTR_ENTITY_CUSTOM_DATA = '@@sidebar/CTR_ENTITY_CUSTOM_DATA',
}

export enum RuleAdminTypes {
  SCENARIO_CONFIG = '@@ruleAdmin/SCENARIO_CONFIG',
  RULE_CONTENT = '@@ruleAdmin/RULE_CONTENT',
  RULE_QUERY = '@@ruleAdmin/RULE_QUERY',
  RULE_EXECUTION = '@@ruleAdmin/RULE_EXECUTION',
  RULE_VALIDATION = '@@ruleAdmin/RULE_VALIDATION',
  RULE_SCHEDULE = '@@ruleAdmin/RULE_SCHEDULE',
  ALERT_CONTENT = '@@ruleAdmin/ALERT_CONTENT',
  ALERT_HIT = '@@ruleAdmin/ALERT_HIT',
  ORG_SETTINGS = '@@ruleAdmin/ORG_SETTINGS',
}

interface IDPayload {
  id: number;
}

interface CGDOIDPayload {
  id: number | string;
}

export interface TableConfigSidebarDefinition {
  data: TableConfigType;
  type: SidebarComponentTypes.TABLE_CONFIG;
}

export interface RuleAdminSidebarDefinition {
  data: {
    content: RuleAdminResponseType;
    contentType: RuleAdminTypes;
    contentHeader: TableConfig[];
  };
  type: SidebarComponentTypes.RULE_ADMIN;
}

export interface SARSidebarDefinition {
  data: IDPayload & { hideActionButton?: boolean };
  type: SidebarComponentTypes.SAR;
}

export interface FilingsBatchDefinition {
  data: {
    id: number;
    pageType: FincenCtrTablePages;
  };
  type: SidebarComponentTypes.FILINGS_BATCH;
}

export interface TransactionSidebarDefinition {
  data: CGDOIDPayload;
  type: SidebarComponentTypes.TRANSACTION;
}

export interface EntitySidebarDefinition {
  data: CGDOIDPayload & { externalId?: string };
  type: SidebarComponentTypes.ENTITY;
}

export interface ActionEventSidebarDefinition {
  data: CGDOIDPayload;
  type: SidebarComponentTypes.ACTION_EVENT;
}

export interface TxnInstrumentSidebarDefinition {
  data: CGDOIDPayload;
  type: SidebarComponentTypes.INSTRUMENT;
}

export interface CaseSidebarDefinition {
  data: IDPayload & { hideActionButton?: boolean };
  type: SidebarComponentTypes.CASE;
}

export interface AlertSidebarDefinition {
  data: {
    alert: IDPayload;
    hideActionButton?: boolean;
  };
  type: SidebarComponentTypes.ALERT;
}

export interface CustomDataSidebarDefinition {
  data: {
    customData: JSONObject;
    customDataLabels: Record<string, { label: string; description: string }>;
  };
  type: SidebarComponentTypes.CTR_ENTITY_CUSTOM_DATA;
}

export type SidebarDefinition =
  | TransactionSidebarDefinition
  | EntitySidebarDefinition
  | ActionEventSidebarDefinition
  | TxnInstrumentSidebarDefinition
  | CaseSidebarDefinition
  | AlertSidebarDefinition
  | TableConfigSidebarDefinition
  | RuleAdminSidebarDefinition
  | SARSidebarDefinition
  | FilingsBatchDefinition
  | CustomDataSidebarDefinition
  | {
      data: Record<string, never>;
      type:
        | SidebarComponentTypes.FEATURE_FLAGS
        | SidebarComponentTypes.LINK_ANALYSIS_OBJECT
        | SidebarComponentTypes.PERMISSIONS
        | SidebarComponentTypes.FFIP_VIEW_ERRORS
        | SidebarComponentTypes.CREATE_EDIT_DATA_SETTING;
    };
