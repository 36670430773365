import { createSelector } from 'reselect';
import { Route } from 'app/shared/utils/routes';
import {
  CustomConfigType,
  CustomDataConfigLabels,
  CustomInterfaceSettings,
  CustomTableConfig,
  TableConfigActionTypes,
  TableConfigType,
} from 'app/shared/CustomConfig/models';
import { selectAvailableHomeRoutes } from 'app/modules/session/selectors';
import { FeatureFlag } from 'app/shared/featureFlags/models';
import { selectFeatureFlags } from 'app/shared/featureFlags/selectors';
import {
  buildDataSettingColumn,
  buildNonCustomDataTableColumn,
  getTableConfigs,
} from 'app/shared/CustomConfig/helpers';
import { selectMergedTableSettingsByClassifier } from 'app/modules/tableSettings/selectors';
import {
  OrgTableColumnKeyType,
  OrgTableConfig,
} from 'app/modules/tableSettings/responses';
import { CLASSIFIER_TO_LABEL_MAP } from 'app/modules/tableSettings/constants';
import {
  OrgDataSettingsConfig,
  Unit21DataClassifier,
} from 'app/modules/dataSettings/responses';
import { TableConfig } from 'app/shared/pagination/models';
import {
  selectCustomDataTableLabelsByClassifier,
  selectDataSettingsById,
} from 'app/modules/dataSettings/selectors';
import { QueueType } from 'app/modules/queues/models';
import { ALERT_SCORE_COLUMN } from 'app/modules/alerts/columns';

export const selectCustomConfigs = (state: RootState) =>
  state.tableConfig.configs;

export const selectCustomConfigsLoading = (state: RootState) => {
  return Boolean(
    state.loading[TableConfigActionTypes.RETRIEVE_ORG_TABLE_CONFIG],
  );
};

export const saveCustomConfigsLoading = (state: RootState) => {
  return Boolean(state.loading[TableConfigActionTypes.SAVE_TABLE_CONFIG]);
};

export const selectTableConfigs = createSelector(
  selectCustomConfigs,
  selectFeatureFlags,
  (configs, featureFlags) =>
    getTableConfigs(configs, featureFlags) as Record<
      TableConfigType,
      CustomTableConfig
    >,
);

export const selectCustomDataConfigs = createSelector(
  selectCustomConfigs,
  (configs) =>
    Object.keys(configs).reduce((acc, key) => {
      if (CustomDataConfigLabels[key]) {
        acc[key] = configs[key];
      }
      return acc;
    }, {}),
);

export const convertTableConfig = (
  { columns, type_classifier: classifier }: OrgTableConfig,
  dataSettingsById: Record<number, OrgDataSettingsConfig | undefined> | null,
): TableConfig[] => {
  return columns.reduce<TableConfig[]>((acc, column) => {
    if (column.key_type === OrgTableColumnKeyType.DATA_SETTING) {
      // shouldn't be possible but
      if (!dataSettingsById?.[column.data_setting_id]) {
        return acc;
      }
      const dataSetting = dataSettingsById[column.data_setting_id];
      if (dataSetting) {
        acc.push(buildDataSettingColumn(dataSetting));
      }
      return acc;
    }
    const label =
      CLASSIFIER_TO_LABEL_MAP[classifier][column.key] || 'Unknown Column';
    acc.push(buildNonCustomDataTableColumn(column.key, label));
    return acc;
  }, []);
};

export const selectEntityTableConfig = createSelector(
  selectMergedTableSettingsByClassifier,
  selectDataSettingsById,
  (tableSettings, dataSettingsById) => {
    const entitySettings =
      tableSettings && tableSettings[Unit21DataClassifier.ENTITY];
    return entitySettings
      ? convertTableConfig(entitySettings, dataSettingsById)
      : [];
  },
);

export const selectInstrumentTableConfig = createSelector(
  selectMergedTableSettingsByClassifier,
  selectDataSettingsById,
  (tableSettings, dataSettingsById) => {
    const instrumentSettings =
      tableSettings && tableSettings[Unit21DataClassifier.INSTRUMENT];
    return instrumentSettings
      ? convertTableConfig(instrumentSettings, dataSettingsById)
      : [];
  },
);

export const selectTxnTableConfig = createSelector(
  selectMergedTableSettingsByClassifier,
  selectDataSettingsById,
  (tableSettings, dataSettingsById) => {
    const eventSettings =
      tableSettings && tableSettings[Unit21DataClassifier.EVENT];
    return eventSettings
      ? convertTableConfig(eventSettings, dataSettingsById)
      : [];
  },
);

export const selectActionEventTableConfig = createSelector(
  selectMergedTableSettingsByClassifier,
  selectDataSettingsById,
  (tableSettings, dataSettingsById) => {
    const actionEventTableSettings =
      tableSettings && tableSettings[Unit21DataClassifier.ACTION_EVENT];
    return actionEventTableSettings
      ? convertTableConfig(actionEventTableSettings, dataSettingsById)
      : [];
  },
);

export const selectAlertTableConfig = createSelector(
  selectMergedTableSettingsByClassifier,
  selectDataSettingsById,
  selectFeatureFlags,
  (tableSettings, dataSettingsById, featureFlags) => {
    const alertSettings =
      tableSettings && tableSettings[Unit21DataClassifier.ALERT];
    return alertSettings
      ? convertTableConfig(alertSettings, dataSettingsById).filter((config) => {
          return (
            config.key !== ALERT_SCORE_COLUMN.id ||
            featureFlags[FeatureFlag.ALERT_SCORE]
          );
        })
      : [];
  },
);

export const selectCaseTableConfig = createSelector(
  selectMergedTableSettingsByClassifier,
  (tableSettings) => {
    const caseSettings =
      tableSettings && tableSettings[Unit21DataClassifier.CASE];
    return caseSettings ? convertTableConfig(caseSettings, {}) : [];
  },
);

export const selectLiveModelTableConfig = createSelector(
  selectMergedTableSettingsByClassifier,
  (tableSettings) => {
    const rulesSettings =
      tableSettings && tableSettings[Unit21DataClassifier.RULE];
    return rulesSettings ? convertTableConfig(rulesSettings, {}) : [];
  },
);

export const selectWatchlistTableConfig = createSelector(
  selectMergedTableSettingsByClassifier,
  selectDataSettingsById,
  (tableSettings, dataSettingsById) => {
    const watchlistSettings =
      tableSettings && tableSettings[Unit21DataClassifier.WATCHLIST];
    return watchlistSettings
      ? convertTableConfig(watchlistSettings, dataSettingsById)
      : [];
  },
);

export const selectLinkAnalysisTableConfig = (state: RootState) =>
  state.tableConfig.configs[TableConfigType.LINK_ANALYSIS_TABLE]?.definition ||
  [];

export const selectCensorshipTableConfig = (state: RootState) =>
  state.tableConfig.configs[TableConfigType.CENSORSHIP_TABLE]?.definition || [];

export const selectScenarioConfigAdminTableConfig = (state: RootState) =>
  state.tableConfig.configs[TableConfigType.RULE_ADMIN_SCENARIO_CONFIG_TABLE]
    ?.definition || [];

export const selectRuleContentAdminTableConfig = (state: RootState) =>
  state.tableConfig.configs[TableConfigType.RULE_ADMIN_CONTENT_TABLE]
    ?.definition || [];

export const selectRuleExecutionAdminTableConfig = (state: RootState) =>
  state.tableConfig.configs[TableConfigType.RULE_ADMIN_EXECUTION_TABLE]
    ?.definition || [];

export const selectRuleQueryAdminTableConfig = (state: RootState) =>
  state.tableConfig.configs[TableConfigType.RULE_ADMIN_QUERY_TABLE]
    ?.definition || [];

export const selectRuleValidationAdminTableConfig = (state: RootState) =>
  state.tableConfig.configs[TableConfigType.RULE_ADMIN_VALIDATION_TABLE]
    ?.definition || [];

export const selectRuleScheduleAdminTableConfig = (state: RootState) =>
  state.tableConfig.configs[TableConfigType.RULE_ADMIN_SCHEDULE_TABLE]
    ?.definition || [];

export const selectAlertContentAdminTableConfig = (state: RootState) =>
  state.tableConfig.configs[TableConfigType.RULE_ADMIN_ALERT_CONTENT_TABLE]
    ?.definition || [];

export const selectAlertHitAdminTableConfig = (state: RootState) =>
  state.tableConfig.configs[TableConfigType.RULE_ADMIN_ALERT_HIT_TABLE]
    ?.definition || [];

export const selectOrgSettingsAdminTableConfig = (state: RootState) =>
  state.tableConfig.configs[TableConfigType.RULE_ADMIN_ORG_SETTINGS_TABLE]
    ?.definition || [];

export const selectFilingsTableConfig = (state: RootState) =>
  state.tableConfig.configs[TableConfigType.SAR_TABLE]?.definition || [];

export const selectAlertQueuesTableConfig = (state: RootState) =>
  state.tableConfig.configs[TableConfigType.ALERT_QUEUES_TABLE]?.definition ||
  [];

export const selectCaseQueuesTableConfig = (state: RootState) =>
  state.tableConfig.configs[TableConfigType.CASE_QUEUES_TABLE]?.definition ||
  [];

export const selectSarQueuesTableConfig = (state: RootState) =>
  state.tableConfig.configs[TableConfigType.SAR_QUEUES_TABLE]?.definition || [];

export const selectDataSettingsTableConfig = (state: RootState) =>
  state.tableConfig.configs[TableConfigType.DATA_SETTINGS]?.definition || [];

export const selectDashboardSettings = (
  state: RootState,
): CustomInterfaceSettings =>
  (state.tableConfig.configs[CustomConfigType.INTERFACE_SETTINGS]
    ?.definition as CustomInterfaceSettings) || {};

export const selectCustomHomePage = createSelector(
  selectDashboardSettings,
  selectAvailableHomeRoutes,
  (settings, availableHomeRoutes): Route | undefined => {
    // Returns the custom home page if the user defined one and is available for them (have right permissions)
    if (settings.homePage) {
      return availableHomeRoutes.find(
        (route) => route.id === settings.homePage,
      );
    }
    return undefined;
  },
);

export const selectEntityCustomDataSettings = createSelector(
  selectCustomConfigs,
  (configs) =>
    configs[CustomConfigType.ENTITY_CUSTOM_DATA_SETTINGS]?.definition || [],
);

export const selectTxnCustomDataSettings = createSelector(
  selectCustomConfigs,
  (configs) =>
    configs[CustomConfigType.TXN_CUSTOM_DATA_SETTINGS]?.definition || [],
);

export const selectInstrumentCustomDataSettings = createSelector(
  selectCustomConfigs,
  (configs) =>
    configs[CustomConfigType.INSTRUMENT_CUSTOM_DATA_SETTINGS]?.definition || [],
);

export const selectEntityCustomDataKeyLabelMap = createSelector(
  selectCustomDataTableLabelsByClassifier,
  (data) => data.ENTITY,
);

export const selectTxnCustomDataKeyLabelMap = createSelector(
  selectCustomDataTableLabelsByClassifier,
  (data) => data.EVENT,
);

export const selectInstrumentCustomDataKeyLabelMap = createSelector(
  selectCustomDataTableLabelsByClassifier,
  (data) => data.INSTRUMENT,
);

export const selectAlertCustomDataKeyLabelMap = createSelector(
  selectCustomDataTableLabelsByClassifier,
  (data) => data.ALERT,
);

export const selectQueuesTableConfig = (queueType: QueueType) =>
  createSelector(
    selectAlertQueuesTableConfig,
    selectCaseQueuesTableConfig,
    selectSarQueuesTableConfig,
    (alertTableConfig, caseTableConfig, sarTableConfig) => {
      switch (queueType) {
        case QueueType.ALERT_QUEUE:
          return alertTableConfig;
        case QueueType.CASE_QUEUE:
          return caseTableConfig;
        case QueueType.SAR_QUEUE:
          return sarTableConfig;
        default:
          throw new Error(`No implementation for ${queueType}`);
      }
    },
  );
